import React from "react";
import linkedln from "../../assests/png/linkedln.png";
import { CiMail } from "react-icons/ci";
import arrow from "../../assests/png/arrow.png";

const teams = [
  {
    id: 1,
    img: require("../../assests/png/sammm.png"),
    name: "Sam Ladipo",
    title: "Founder & CEO",
    info: "",
    linkedln: linkedln,
  },
  {
    id: 2,
    img: require("../../assests/png/joseph.png"),
    name: "Joseph Emmanuel",
    title: "Lead Designer",
    info: "",
    linkedln: linkedln,
  },
  {
    id: 3,
    img: require("../../assests/png/marketing.png"),
    name: "Daniel Aikhomogbe",
    title: "Lead Marketimg Manager",
    info: "",
    linkedln: linkedln,
  },
  {
    id: 1,
    img: require("../../assests/png/ezekiel.png"),
    name: "Ezekiel",
    title: "Frontend Engineer",
    info: "",
    linkedln: linkedln,
  },
  {
    id: 1,
    img: require("../../assests/png/ebi.png"),
    name: "Ebenezer Noble",
    title: "Frontend Engineer",
    info: "",
    linkedln: linkedln,
  },

];

function Team() {
  return (
    <section className="flex flex-col items-center justify-center md:h-[1374px] about-padding gap-[64px]">
      <div className="flex flex-col items-center h-[94px] gap-[20px]">
        <h1 className="inter text-[36px] font-semibold leading-[44px] tracking-tight text-[#101828]">
          Meet our team
        </h1>
        <p className="inter text-[20px] font-normal leading-[30px] text-[#475467] text-center">
          We actually are who we claim to be. Superstars in the making.
        </p>
      </div>
      <section className="grid md:grid-cols-3  grid-cols-1 gap-10">
        {teams.map((team) => {
          const { id, img, name, title, info, linkedln } = team;
          return (
            <div
              style={{
                backgroundImage: `url(${img})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
              className="h-[480px] bg-black w-[384px] flex-1 relative "
              key={id}
            >
              <div className="md:w-[336px] w-[80%] md:h-[150px] absolute top-[47%] left-[6%] md:px-5 md:py-6 px-2 py-2 mt-[75px] md:mt-[70px] bg-black  bg-opacity-10     border border-white border-opacity-50 backdrop-blur-xl flex-col justify-start items-start gap-10 inline-flex">
                <div className="self-stretch md:h-20 h-[100px] flex-col justify-start items-start gap-2 flex">
                  <div className="self-stretch justify-start items-start gap-4 inline-flex">
                    <div className="grow shrink basis-0 text-white text-2xl font-bold font-['Inter'] leading-loose">
                      {name}
                    </div>
                    <div className="pt-3 flex-col justify-start items-start inline-flex cursor-pointer">
                      <img src={arrow} alt="arrow" />
                    </div>
                  </div>
 
                    <div className="self-stretch text-white text-lg font-semibold font-['Inter'] leading-7">
                      {title}
                    </div>
        
                </div>
                <div className="self-stretch justify-start items-center gap-5 inline-flex cursor-pointer  mt-[-30px]">
                  <img src={linkedln} alt="linkedln" />
                  <CiMail className="h-6 w-6 text-white" />
                </div>
              </div>
            </div>
          );
        })}
      </section>
    </section>
  );
}

export default Team;
