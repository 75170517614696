import React from "react";
import logo from "../assests/png/Frame 7.png";
import NavIcon from "../assests/logos/mobile-nav.png";
import { Link } from "react-router-dom";
import { FaTimes } from 'react-icons/fa/' 
function MobileNav() {
  const [isMobileMenuOpen, setMobileMenuOpen] = React.useState(false);

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <div className="fixed w-full shadow-lg z-10 bg-white md:hidden">
      <div className="flex items-center w-full justify-between h-[80px] px-7">
        <Link to="/">
          <img src={logo} className="w-[35%]" alt="logo" />
        </Link>
        <div className="flex items-center cursor-pointer" onClick={handleMobileMenuToggle} >
            {
                isMobileMenuOpen ? <FaTimes size={30} color="blue"/> :   <img src={NavIcon} alt="" className="w-32" />
            }
       
        </div>
      </div>

      {isMobileMenuOpen && (
        <div className="mobile-menu px-4 py-4">
          <ul>
            <li className="mb-4 border-b-[1px]  h-[50px]  border-b-slate-300">
              <Link
                to="/about"
                className="text-[16px] leading-[24px] text-[#475467] font-semibold inter"
                onClick={closeMobileMenu}
              >
                About us
              </Link>
            </li>
            <li className="mb-4 border-b-[1px] h-[50px] border-b-slate-300">
              <Link
                to="/services"
                className="text-[16px] leading-[24px] text-[#475467] font-semibold inter"
                onClick={closeMobileMenu}
              >
                Our Services
              </Link>
            </li>
            <li>
              <button className="w-[155px] h-[60px] gap-[12px] rounded-[8px] border-[1px] bg-[#1F3AE3] btn-padding">
                <Link
                  to="/contact"
                  className="text-[18px] leading-[28px] font-semibold inter text-white"
                  onClick={closeMobileMenu}
                >
                  {" "}
                  Let's Talk{" "}
                </Link>
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default MobileNav;
